import React from 'react';


function About() {
    const handleClick = (event, target) => {
        event.preventDefault();
        document.querySelector(target).scrollIntoView({
            behavior: "smooth"
        });
    };
    return (
        <section id="about" style={{width: "100%"}}>
            <div className="grid-container">
                <div className="grid-item-circle">
                    <img src={"./assets/GoobyDev.png"} alt="GoobyDev Profile" />
                </div>
                <div className="grid-item" style={{width: "100%"}}>
                    <h2 className="base-header">Hey! I'm Codey Arch</h2>
                    <div className="desktopAboutDesc">
                        <p>I'm a second year Undergraduate Student at the University of Essex. I love making apps, games and websites and I'm currently studying to improve my ability to do this.
                            In particular, I enjoy studying game design and game programming and I work on my own projects in-between assignments.
                            Outside of making things, I also participate in beta tests for upcoming games, using my talents at breaking things in order to help others fix them!
                            <br/><br/>
                            I started programming when I was 12 years old, making simplistic python scripts and terminal games. That evolved to
                            making my own Unity games and my own Android Apps, alongside educational projects. You can check some of these out in the projects section below.
                            <br/><br/>
                            Most of my experience is solo work but I have experience working in teams for projects such as Skyhouse with Skyblock.tools.
                            When I work in a team, I build a strong connection with my teammates because
                            I enjoy getting to know my team and learning about the goals of any business I work with.
                            <br/><br/>
                            If you want to learn more about me, check out my projects or contact me via the form or my socials.</p>
                    </div>
                    <div className="mobileAboutDesc">
                        <p>I'm a second year Undergraduate Student at the University of Essex. I love making apps, games and websites and I'm currently studying to improve my ability to do this.
                            I have been programming since I was 12 and making my own passion projects for over 2 years
                            <br/><br/>
                            My main experience is solo work but I have experience working in teams for projects such as Skyhouse with Skyblock.tools.
                            When I work in a team, I build a strong connection with my teammates because
                            I enjoy getting to know my team and learning about the goals of any business I work with.
                            <br/><br/>
                            If you want to learn more about me, check out my projects or contact me via the form or my socials</p>
                    </div>

                    <div>
                        <button className="btn-lime" onClick={(e) => handleClick(e, "#contact")}>Get In Contact</button>
                        <button className="btn-gray" onClick={(e) => handleClick(e, "#projects")}>See My Projects</button>

                    </div>
                </div>

            </div>
        </section>
    );
}

export default About;